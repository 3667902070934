import {
  CloseOutlined,
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Image, Input, Menu } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "../store/reduxHooks";
import { clearLocalStorage } from "../util/localStorage.util";
import logoMain from '../assets/images/logo2.svg';
import useImage from '../assets/images/user.png'
import loadingImage from '../assets/images/user.jpg'
import { logOut } from "../api/auth";
import { openErrorNotification } from "../components/notification";
import { setPassErrParse } from "../util/common.util";
interface IProps {
  handleSidebarTrigger: () => void;
  onClose: () => void;
  showDrawer: () => void;
  sidebarColllapse: boolean;
}

const Navbar = (props: IProps) => {
  const history = useHistory();
  const { user, loadingAuth } = useAppSelector((state) => state.authReducer);

  const [showResponsiveSearch, setShowResponsiveSearch] =
    useState<boolean>(false);

  const handleResponsiveSearch = () => {
    setShowResponsiveSearch(true);
  };

  const handleResponsiveSearchClose = () => {
    setShowResponsiveSearch(false);
  };

  const signOutHandler = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    let user_id = {
      userId: user?.data._id
    }
    const [response, error] = await logOut(user_id);
    if (error) {
      openErrorNotification(setPassErrParse(error), "Log out failed")
    }
    if (response) {
      clearLocalStorage();
      history.push("/login");
    }
  };

  // const menuNotification = (
  //   <Menu className="notification__dropdown">
  //     <Menu.Item>
  //       <div className="notification__header d-flex align-items-center">
  //         <h4>Notification</h4>
  //         <Button type="link" className="clear__btn">
  //           Clear
  //         </Button>
  //       </div>
  //     </Menu.Item>

  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <div className="notification__list">
  //         <Avatar size={40}>
  //           <img src={Profile} alt="" />
  //         </Avatar>
  //         <div className="comment__section">
  //           <span className="commentor__name">Erin Gonzales </span>
  //           <span className="comment__text">has comment on your board</span>
  //         </div>
  //         <span className="comment__time">7:57PM</span>
  //       </div>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const menuLanguage = (
  //   <Menu>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         English
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.aliyun.com"
  //       >
  //         Japanese
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.luohanacademy.com"
  //       >
  //         Korean
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // );

  const menuProfile = (
    <Menu className="profile__dropdown">
      <Menu.Item>
        <Link
          to="/profile"
        >
          <span className="link__iocns">
            <UserOutlined />
          </span>
          Profile
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="#" onClick={signOutHandler}>
          <span className="link__iocns">
            <LogoutOutlined />
          </span>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="page__header">
      <div
        className={`header__logo justify-content-center  ${props.sidebarColllapse ? "shrinked" : ""
          } desktop`}
      >
        <Image src={logoMain} preview={false} />
      </div>
      <div className="header__main">
        <div
          className={`responsive__search ${showResponsiveSearch ? "show" : ""}`}
        >
          <div className="search__wrapper">
            <Button>
              <SearchOutlined />
            </Button>
            <Input placeholder="Basic usage" />
          </div>
          <Button
            onClick={handleResponsiveSearchClose}
            className="btn__close__search"
          >
            <CloseOutlined />
          </Button>
        </div>
        <Button
          className="btn__menu__toggle"
          onClick={props.handleSidebarTrigger}
        >
          {props.sidebarColllapse ? (
            <MenuUnfoldOutlined />
          ) : (
            <MenuFoldOutlined />
          )}
        </Button>
        <Button
          onClick={props.showDrawer}
          className="btn__menu__toggle drawer__trigger"
        >
          <MenuUnfoldOutlined />
        </Button>
        {/* <div className="search__wrapper">
          <Button>
            <SearchOutlined />
          </Button>
          <Input placeholder="Basic usage" />
        </div> */}
        <ul className="navigation__right">
          <li className="display__lg">
            <Button
              className="btn__search__sm"
              onClick={handleResponsiveSearch}
            >
              <SearchOutlined />
            </Button>
          </li>
          <li className="d-flex align-items-center">
            <div className="username_role">
              <h4 className="user__name">{user?.data.userName}</h4>
              <span className="work__department text__capitalize ">
                {user?.data.role}
              </span>
            </div>
          </li>
          <li className="avatar_container d-flex align-items-center">
            <Dropdown
              overlay={menuProfile}
              placement="bottomRight"
              arrow
              trigger={["click"]}
            >
              <span className="trigger-section">
                <Avatar size="large">
                  {(user?.data.imageUrl && !loadingAuth) ?
                    (<Image src={user?.data.imageUrl} preview={false} />)
                    :
                    (user?.data.imageUrl === undefined && !loadingAuth ?
                      <Image src={useImage} preview={false} />
                      : (
                        <Image src={loadingImage} preview={false} className="loader_image" />
                      ))
                  }
                </Avatar>
              </span>
            </Dropdown>
            <DownOutlined />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
