import { IStudioUserInvoiceReducer } from '../../interfaces/studioUserInvoice.interface';
import * as studioUserActionTypes from '../actions/studio/studioUserActions';

const initState: IStudioUserInvoiceReducer = {
    studioUserInvoice: [],
    studioInvoiceLoading: false,
    userId: ''
    // has_more: false,
    // next_page: ""
}

export const studioUserInvoiceReducer = (state = initState, action: any): IStudioUserInvoiceReducer => {
    switch (action.type) {
        case studioUserActionTypes.GET_INVOICE:
            return {
                ...state,
                studioInvoiceLoading: true
            }

        case studioUserActionTypes.GET_INVOICE_SUCCESS:
            return {
                ...state,
                studioInvoiceLoading: false,
                studioUserInvoice:[...action.payload],
                userId: action.userId
            }

        case studioUserActionTypes.GET_INVOICE_ERROR:
            return {
                ...state,
                studioInvoiceLoading: false
            }

        default:
            return state;
    }
}