import { IStudioResetPasswordReducer } from '../../interfaces/studioResetPassword.interface';
import * as studioUserActionTypes from '../actions/studio/studioUserActions';

const initState: IStudioResetPasswordReducer = {
    studioResetPassword: null
}

export const studioResetPasswordReducer = (state = initState, action: any) => {
    switch (action.type) {
        case studioUserActionTypes.RESET_PASSWORD:
            return {
                ...state,
                loading: true
            }

        case studioUserActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                studioResetPassword: {
                    data: action.payload.data
                }
            }

        case studioUserActionTypes.RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}