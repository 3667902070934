import { IStudioSingleUserReducer } from '../../interfaces/studioSingleUser.interface';
import * as studioUserActionTypes from '../actions/studio/studioUserActions';

const initState: IStudioSingleUserReducer = {
    singleStudioUser: null,
    loading: false
}
export const studioUserEditReducer = (state = initState, action: any): IStudioSingleUserReducer => {
    switch(action.type){
        case studioUserActionTypes.EDIT_USER:
            return {
                ...state,
                loading: true
            }
        case studioUserActionTypes.EDIT_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                singleStudioUser: {
                    data: action.payload.data,
                    userId: action.payload.userId
                }
            }
            
        case studioUserActionTypes.GET_SINGLE_STUDIO_ERROR:
            return{
                ...state,
                loading: false
            }
        default:
            return state;
    }
}
