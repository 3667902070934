import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
// import "./App.less";
import { ErrorBoundary } from "./hoc/ErrorBoundary";
import Routes from "./routes";
// import 'antd/dist/antd.compact.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/fonts/fonts.css";
import "./assets/less/main.less";
// import './assets/sass/main.dark.css';
import { PersistGate } from 'redux-persist/integration/react'
import {store, persistor} from "./store/store"

function App() {
  return (
    <BrowserRouter>
     <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        
        <Provider store={store}>
          <Routes />
        </Provider>
      </ErrorBoundary>
      </PersistGate>
    </BrowserRouter>
  );
}

export default App;
