
import { combineReducers } from "@reduxjs/toolkit";
import { adminUsersReducer } from "../reducers/adminUsersReducer";
import { studioUserReducer } from "../reducers/studioUserReducer";
import { changeStudioUserTypeReducer } from "../reducers/changeStudioUserTypeReducer";
import { adminSingleUserReducer } from "../reducers/adminSingleUserReducer"
import { studioSingleUserReducer } from "../reducers/studioSingleUserReducer";
import { studioResetPasswordReducer } from "../reducers/studioUserResetPasswordReducer";
import { studioUserInvoiceReducer } from "../reducers/studioUserInvoiceReducer";
import { studioUserEditReducer } from "../reducers/studioUserEditReducer";
import { authReducer } from "../reducers/authReducer";


const RootReducer = combineReducers({
    authReducer: authReducer,
    adminUsersReducer:adminUsersReducer,
    studioUserReducer:studioUserReducer,
    changeStudioUserTypeReducer:changeStudioUserTypeReducer,
    adminSingleUserReducer:adminSingleUserReducer,
    studioSingleUserReducer:studioSingleUserReducer,
    studioResetPasswordReducer:studioResetPasswordReducer,
    studioUserInvoiceReducer:studioUserInvoiceReducer,
    studioUserEditReducer:studioUserEditReducer
   
})

export default RootReducer
