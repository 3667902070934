import { Breadcrumb, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch } from "react-router";
import PageUnavailable from "../pages/Components/PageUnavailable";
import { mainRoutes } from "../routes/main.routes";
import { getProfileAction } from "../store/actions/auth/authActionCreators";
import { useAppDispatch } from "../store/reduxHooks";
import { isUserLoggedIn } from "../util/auth.util";
import { errorParser } from "../util/common.util";
import Header from "./header";
import Navdrawer from "./navdrawer";
import { openErrorNotification } from "./notification";
import Sidenav from "./sidenav";

const { Content } = Layout;

function MainLayout() {
  const [sidebarColllapse, setSidebarColllapse] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const hideDrawer = () => {
    setVisible(false);
  };

  const handleSidebarTrigger = () => {
    setSidebarColllapse(!sidebarColllapse);
  };

  useEffect(() => {
    if (isUserLoggedIn()) {
      dispatch(
        getProfileAction((data?: any, err?: any) => {
          if (err) {
            openErrorNotification(errorParser(err), "Error");
          }
          if(data){
            return true;
          }
        })
      );
    }
  }, [dispatch]);

  if (!isUserLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <PageUnavailable />
    
      <div className="dektop_device">
        <Header
          sidebarColllapse={sidebarColllapse}
          handleSidebarTrigger={handleSidebarTrigger}
          onClose={onClose}
          showDrawer={showDrawer}
        />
        <div className="sidenav-container">
          <Sidenav sidebarColllapse={sidebarColllapse} />
        </div>
        <Navdrawer onClose={onClose} visible={visible} hideDrawer={hideDrawer} />
        <Content
          className={`page__wrapper ${sidebarColllapse ? "expanded" : ""}`}
        >
          <Switch>
            {mainRoutes}
            <Redirect to="/pagenotfound" />
          </Switch>
        </Content>
      </div>
    </>
  );
}

export default MainLayout;
