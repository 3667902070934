export const errorParser = (err: any) => {
  if (err?.response?.data?.error) {
    return err?.response?.data?.error?.message;
  } else {
    return "Something went wrong";
  }
};

export const setPassErrParse = (err: any) => {
  if (err.message) {
    return err.message;
  } else {
    return "Something went wrong";
  }
};

export const numberWithCommas = (x: number | string) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
