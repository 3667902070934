import { AppDispatch } from "./../../store";
import { getProfile } from "./../../../api/auth";
import * as authActions from "./authActions";
import { getAuthToken } from '../../../util/localStorage.util';
import { clearLocalStorage } from "../../../util/localStorage.util";
import axiosInstance from '../../../axios/axios';

export const checkLogin = (isAuth: boolean) => {
  // some aync logic
  return {
    type: authActions.CHECK_TOKEN,
    isAuth: isAuth,
  };
};

export const getProfileAction =
  (cb: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: authActions.GET_PROFILE
      })
      const response = await axiosInstance({
        method: "GET",
        url: `/auth/me`,
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        }
      });
      if (response.status === 200) {
        dispatch({
          type: authActions.GET_PROFILE_DATA_SUCCESS,
          payload: response.data,
        })
        cb(response.data, null)
      }
    } catch (error: any) {
      cb(error.data, null)
      dispatch({
        type: authActions.GET_PROFILE_DATA_ERROR
      })
      if (error.message.includes('401')) {
        clearLocalStorage()
      }
    }
  };
