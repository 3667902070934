import axiosInstance from "../axios/axios";
import { LocalStorageKeys } from "../enum/enum";

export const getAuthToken = (): string | null => {
  return localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
  localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
};

export const addAuthToLocalStorage = (token: string ) => {
  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token);  
};

export const setHeaders = () => {
  if (getAuthToken()) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.oktaToken}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};
