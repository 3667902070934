import { IAuthReducer } from "./../../interfaces/auth.interface";
import * as authActions from "../actions/auth/authActions";

const initState: IAuthReducer = {
  user: null,
  loadingAuth: false,
};

export const authReducer = (state = initState, action: any): IAuthReducer => {
  switch (action.type) {
    case authActions.GET_PROFILE: {
      return {
        ...state,
        loadingAuth: true,
        user: action.payload,
      };
    }

    case authActions.GET_PROFILE_DATA_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loadingAuth: false
      }
    }

    case authActions.GET_PROFILE_DATA_ERROR: {
      return {
        ...state,
        loadingAuth: false
      }
    }

    default: {
      return state;
    }
  }
};



