import { IAdminSingleUserReducer } from '../../interfaces/adminSingleUser.interface';
import * as adminUserActionTypes from '../actions/admin/adminUserActions';

const initState: IAdminSingleUserReducer = {
    adminSingleUser: null,
    loading: false
}

export const adminSingleUserReducer = (state = initState, action: any): IAdminSingleUserReducer => {
    switch (action.type) {
      
        case adminUserActionTypes.GET_ADMIN_USER_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                adminSingleUser: {
                    data: action.payload,
                }
            }

        case adminUserActionTypes.GET_ADMIN_USER_DATA_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}