export const GET_STUDIO_USER_DATA = "GET_STUDIO_USER_DATA"
export const GET_STUDIO_USER_DATA_SUCCESS = "GET_STUDIO_USER_DATA_SUCCESS"
export const GET_STUDIO_USER_DATA_ERROR = "GET_STUDIO_USER_DATA_ERROR"

export const STUDIO_USER_SEARCH_START = "STUDIO_USER_SEARCH_START"
export const STUDIO_USER_SEARCH_SUCCESS = "STUDIO_USER_SEARCH_SUCCESS"
export const STUDIO_USER_SEARCH_ERROR = "STUDIO_USER_SEARCH_ERROR"

export const GET_SINGLE_STUDIO_USER = "GET_SINGLE_STUDIO_USER"
export const GET_SINGLE_STUDIO_SUCCESS = "GET_SINGLE_STUDIO_SUCCESS"
export const GET_SINGLE_STUDIO_ERROR = "GET_SINGLE_STUDIO_ERROR"

export const DELETE_STUDIO_USER_START = "DELETE_STUDIO_USER_START"
export const DELETE_STUDIO_USER = "DELETE_STUDIO_USER"
export const DELETE_STUDIO_USER_ERROR = "DELETE_STUDIO_USER_ERROR"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"

export const CHANGE_USER_TYPE = "CHANGE_USER_TYPE"
export const CHANGE_USER_TYPE_SUCCESS = "CHANGE_USER_TYPE_SUCCESS"
export const CHANGE_USER_TYPE_ERROR = "CHANGE_USER_TYPE_ERROR"

export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_ERROR = "EDIT_USER_ERROR"

export const GET_INVOICE = "GET_INVOICE"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR"
