import { getAuthToken } from "./../util/localStorage.util";
import axiosInstance from "../axios/axios";
import { IHttp } from "../interfaces/http.interface";
import {
  ILoginRequest,
  ILoginResponse,
  IRegisterResponse,
  IRegisterRequest,
  IPasswordResponse,
  IPasswordRequest,
  IChangePasswordRequest,
  IChangePasswordResponse,
  IChangePasswordConfirmRequest,
  IUser,
  IPasswordResetRequest
} from "./../interfaces/auth.interface";
import { clearLocalStorage } from "../util/localStorage.util";

export const register = async (
  payload: IRegisterRequest
): Promise<[IHttp<IRegisterResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/register", payload);
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

export const setPassword = async (
  payload: IPasswordRequest
): Promise<[IHttp<IPasswordResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/setPassword", payload, {
      headers: {
        Authorization: `Bearer ${payload.token}`,
      }
    });
    return [response, null];
  } catch (error: any) {
    if (error.message.includes('409')) {
      return [null, 'Same password'];
    } else {
      return [null, error];
    }
  }
 
};

export const resetPassword = async (
  payload: IPasswordResetRequest
): Promise<[IHttp<IPasswordResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/resetPassword", payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      }
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};



export const login = async (
  payload: ILoginRequest
): Promise<[IHttp<ILoginResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/login", payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      }
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};


export const getProfile = async (): Promise<[IHttp<IUser>, any]> => {
  try {
    const response = await axiosInstance.get("/auth/me", {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, null];
  } catch (error: any) {
    if (error.message.includes('401')) {
      clearLocalStorage()
    }
    return [null, error];
  }
};


export const logOut = async(payload: any) => {
  try {
    const response = await axiosInstance.post("/auth/logout", payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      }
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
}

export const changePassword = async (payload: IChangePasswordRequest): Promise<[IHttp<IChangePasswordResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/changePassword", payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      }
    });
    return [response, null];
  } catch (error:any) {
    if (error.message.includes('409')) {
      return [null, 'Same password'];
    } else if(error.message.includes('417')){
      return [null, 'Enter new password'];
    } else {
      return [null, error];

    }
  }
};

export const changePasswordConfirm = async (payload: IChangePasswordConfirmRequest): Promise<[IHttp<IChangePasswordResponse>, any]> => {
  try {
    const response = await axiosInstance.post("/auth/changePasswordConfirm", payload, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      }
    });
    return [response, null];
  } catch (error) {
    return [null, error];
  }
};

