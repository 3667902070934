import { IStudioSingleUserReducer } from '../../interfaces/studioSingleUser.interface';
import * as studioUserActionTypes from '../actions/studio/studioUserActions';

const initState: IStudioSingleUserReducer = {
    singleStudioUser: null,
    loading: false
}

export const studioSingleUserReducer = (state = initState, action: any): IStudioSingleUserReducer => {
    switch (action.type) {
        case studioUserActionTypes.GET_SINGLE_STUDIO_USER:
            return{
                ...state,
                loading: true
            }
        case studioUserActionTypes.GET_SINGLE_STUDIO_SUCCESS:
            return {
                ...state,
                loading: false,
                singleStudioUser: {
                    data: action.payload.data,
                    userId: action.payload.userId
                }
            }
        
        case studioUserActionTypes.GET_SINGLE_STUDIO_ERROR:
            return{
                ...state,
                loading: false
            }
    
        default:
            return state;
    }
}
