import { IStudioUserTypeChangeReducer } from '../../interfaces/studioUserType.interface';
import * as studioUserActionTypes from '../actions/studio/studioUserActions';

const initState: IStudioUserTypeChangeReducer = {
    studioChangeUserType: null,
    loading: false
}

export const changeStudioUserTypeReducer = (state = initState, action: any) => {
    switch (action.type) {
        case studioUserActionTypes.CHANGE_USER_TYPE:
            return {
                ...state,
                loading: true
            }

        case studioUserActionTypes.CHANGE_USER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                studioResetPassword: {
                    data: action.payload.data
                }
            }

        case studioUserActionTypes.CHANGE_USER_TYPE_SUCCESS:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}