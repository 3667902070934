import { IStuduiUserReducer } from '../../interfaces/studioUser.interface';
import * as studioUserActionTypes from '../actions/studio/studioUserActions';

const initState: IStuduiUserReducer = {
    studioUser: null,
    studioDeleteMessage: null,
    loading: false
}

export const studioUserReducer = (state = initState, action: any): IStuduiUserReducer => {
    switch (action.type) {
        case studioUserActionTypes.GET_STUDIO_USER_DATA:
            return {
                ...state,
                loading: true
            }

        case studioUserActionTypes.GET_STUDIO_USER_DATA_SUCCESS:
            let currentStudioUsers;
            if (action.payload.page === 0) {
                currentStudioUsers = action.payload.data
            } else {
                currentStudioUsers = state.studioUser?.data.concat(...action.payload.data);
            }
            // if(action.payload.filterData !== null){
            //     let columnName = action.payload.filterData.column;
            //     if(action.payload.filterData?.order === 'ascend'){
            //         currentStudioUsers.sort(function (x:any, y:any) {
            //             return x[columnName] - y[columnName];
            //         })
            //     }else{
            //         currentStudioUsers.sort(function (x:any, y:any) {
            //             return y[columnName] - x[columnName];
            //         })
            //     }
            // }

            return {
                ...state,
                loading: false,
                studioUser: {
                    data: currentStudioUsers,
                    page: action.payload.page,
                    next: action.payload.next,
                    count: action.payload.count
                }
            }

        case studioUserActionTypes.GET_SINGLE_STUDIO_USER:
            return {
                ...state,
                loading: true,
            }

        case studioUserActionTypes.GET_SINGLE_STUDIO_ERROR:
            return {
                ...state,
                loading: false
            }

        case studioUserActionTypes.STUDIO_USER_SEARCH_START:
            return {
                ...state,
                loading: true
            }

        case studioUserActionTypes.STUDIO_USER_SEARCH_SUCCESS:
            let currentStudioUsersSearch;
            if (action.payload.page === 0) {
                currentStudioUsersSearch = action.payload.data
            } else {

                currentStudioUsersSearch = state.studioUser?.data.concat(...action.payload.data);
            }
            // if(action.payload.filterData !== null){
            //     let columnName = action.payload.filterData.column;
            //     if(action.payload.filterData?.order === 'ascend'){
            //         currentStudioUsersSearch.sort(function (x:any, y:any) {
            //             return x[columnName] - y[columnName];
            //         })
            //     }else{
            //         currentStudioUsersSearch.sort(function (x:any, y:any) {
            //             return y[columnName] - x[columnName];
            //         })
            //     }
            // }
            return {
                ...state,
                loading: false,
                studioUser: {
                    data: currentStudioUsersSearch,
                    page: action.payload.page,
                    next: action.payload.next,
                    count: action.payload.count
                }
            }

        case studioUserActionTypes.GET_STUDIO_USER_DATA_ERROR:
            return {
                ...state,
                loading: false
            }

        case studioUserActionTypes.STUDIO_USER_SEARCH_ERROR:
            return {
                ...state,
                loading: false
            }

        case studioUserActionTypes.DELETE_STUDIO_USER_START:
            return {
                ...state,
                loading: true
            }

        case studioUserActionTypes.DELETE_STUDIO_USER:
            // let studioList = state.studioUser?.data.filter((element:any) => {
            //     return element._id !== action.userId             

            // });

            return {
                ...state,
                studioDeleteMessage: action?.payload?.data,
                // studioUser: studioList                
            }

        case studioUserActionTypes.DELETE_STUDIO_USER_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }

}
