import { IAdminUserReducer } from '../../interfaces/adminUsers.interface';
import * as adminUserActionTypes from '../actions/admin/adminUserActions';

const initState: IAdminUserReducer = {
    adminUser: null,
    loading: false
}

export const adminUsersReducer = (state = initState, action: any): IAdminUserReducer => {
    switch (action.type) {
        case adminUserActionTypes.GET_ADMIN_USER_DATA:
            return {
                ...state,
                loading: true
            }

        case adminUserActionTypes.GET_ADMIN_USER_DATA_SUCCESS:
            let currentAdminUsers;
            if (action.payload.page === 0) {
                currentAdminUsers = action.payload.data
            } else {
                currentAdminUsers = state.adminUser?.data.concat(...action.payload.data);
            }

            return {
                ...state,
                loading: false,
                adminUser: {
                    data: currentAdminUsers,
                    page: action.payload.page,
                    next: action.payload.next,
                    count: action.payload.count
                }
            }

        case adminUserActionTypes.ADMIN_USER_SEARCH_START:
            return {
                ...state,
                loading: true
            }

        case adminUserActionTypes.ADMIN_USER_SEARCH_SUCCESS:
            let currentAdminUsersSearch;
            if (action.payload.page === 0) {
                currentAdminUsersSearch = action.payload.data
            } else {

                currentAdminUsersSearch = state.adminUser?.data.concat(...action.payload.data);
            }
            return {
                ...state,
                loading: false,
                adminUser: {
                    data: currentAdminUsersSearch,
                    page: action.payload.page,
                    next: action.payload.next,
                    count: action.payload.count
                }
            }

        case adminUserActionTypes.GET_ADMIN_USER_DATA_ERROR:
            return {
                ...state,
                loading: false
            }

        case adminUserActionTypes.ADMIN_USER_SEARCH_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}