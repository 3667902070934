export const GET_ADMIN_USER_DATA = "GET_ADMIN_USER_DATA"
export const GET_ADMIN_USER_DATA_SUCCESS = "GET_ADMIN_USER_DATA_SUCCESS"
export const GET_ADMIN_USER_DATA_ERROR = "GET_ADMIN_USER_DATA_ERROR"

export const GET_ADMIN_USER_ID_SUCCESS = "GET_ADMIN_USER_ID_SUCCESS"
export const GET_ADMIN_USER_ID_ERROR = "GET_ADMIN_USER_ID_ERROR"

export const ADMIN_USER_SEARCH_START = "ADMIN_USER_SEARCH_START"
export const ADMIN_USER_SEARCH_SUCCESS = "ADMIN_USER_SEARCH_SUCCESS"
export const ADMIN_USER_SEARCH_ERROR = "ADMIN_USER_SEARCH_ERROR"

export const ADMIN_EDIT_USER = "ADMIN_EDIT_USER"
export const ADMIN_EDIT_USER_SUCCESS = "ADMIN_EDIT_USER_SUCCESS"
export const ADMIN_EDIT_USER_ERROR = "ADMIN_EDIT_USER_ERROR"

export const ADMIN_CONFIRM_EMAIL_CHANGE = "ADMIN_CONFIRM_EMAIL_CHANGE"
export const ADMIN_CONFIRM_EMAIL_CHANGE_SUCCESS = "ADMIN_CONFIRM_EMAIL_CHANGE_SUCCESS"
export const ADMIN_CONFIRM_EMAIL_CHANGE_ERROR = "ADMIN_CONFIRM_EMAIL_CHANGE_ERROR"