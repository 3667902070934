import { ArrowLeftOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import Wrong from "../assets/images/wrong.png";
import { Button } from "antd";

export interface IState {
  hasError: boolean;
  errorInfo: any;
}

export class ErrorBoundary extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.redirect = this.redirect.bind(this)
    this.state = {
      hasError: false,
      errorInfo: {},
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  redirect(){
   const baseURL = process.env.CMS_URL;
    window.location.href = `${baseURL}/users/studio`;
  }

  componentDidCatch(err: Error, info: any) {
    console.log(err, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error__boundary__wrapper">
          <img src={Wrong} alt="" />
          <h1>Something went wrong</h1>
          <Button className="link__goback" onClick={this.redirect}>
            <ArrowLeftOutlined /> Go to home page
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}
